import { useMemo } from 'react';

import { useIsBannerSkippedByCookie } from './useIsBannerSkippedByCookie';
import { useIsBannerSkippedByQaTestCookie } from './useIsBannerSkippedByQaTestCookie';
import { useIsBannerSkippedByUTM } from './useIsBannerSkippedByUTM';

export function useIsBannerSkipped(): boolean {
  const isSkippedByCookie = useIsBannerSkippedByCookie();
  const isSkippedByUTM = useIsBannerSkippedByUTM();
  const isSkippedByQaTestCookie = useIsBannerSkippedByQaTestCookie();

  return useMemo(
    () => isSkippedByCookie || isSkippedByUTM || isSkippedByQaTestCookie,
    [isSkippedByCookie, isSkippedByUTM, isSkippedByQaTestCookie],
  );
}
