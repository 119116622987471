import { useDeviceType } from '@cian/ui-kit/responsive';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { acceptCookies } from '../../actions/cookies';
import { CookiesAgreementModal } from '../../components/CookiesAgreementModal';
import { CookiesNotification } from '../../components/CookiesNotification';
import { ICookiesState } from '../../types/cookies';
import { IApplicationState, TThunkDispatch } from '../../types/redux';
import { useApplicationContext } from '../../utils/applicationContext';

export const CookiesNotificationContainer: React.FC = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const deviceType = useDeviceType();
  const { config } = useApplicationContext();
  const [isAgreementOpen, setIsAgreementOpen] = React.useState(false);
  const { accepted } = useSelector<IApplicationState, ICookiesState>(state => state.cookies);
  const noResize = useSelector<IApplicationState, boolean>(state => state.noResize);

  const isWebView = config.get('web.isWebView') || false;
  const cookieAgreementEnabled = config.get<boolean>('header-frontend.cookieAgreement.Enabled') ?? true;

  const isCookiesNotificationEnabled = cookieAgreementEnabled && !accepted && !isWebView && !isAgreementOpen;

  const handleAgreementView = React.useCallback(() => {
    setIsAgreementOpen(true);
  }, []);

  const handleAccept = React.useCallback(() => {
    dispatch(acceptCookies());
    setIsAgreementOpen(false);
  }, [dispatch]);

  return (
    <>
      {isCookiesNotificationEnabled && (
        <CookiesNotification
          noResize={noResize}
          phone={deviceType === 'phone'}
          onAgreementView={handleAgreementView}
          onAccept={handleAccept}
        />
      )}
      <CookiesAgreementModal
        open={isAgreementOpen}
        phone={deviceType === 'phone'}
        onAccept={handleAccept}
        onOpenChange={setIsAgreementOpen}
      />
    </>
  );
};
