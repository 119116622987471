import { ca } from '@cian/analytics';

export function trackLogoClick() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'logo_to_main',
    action: 'click_sopr',
    label: '',
  });
}
