import { useSelector } from 'react-redux';

import { selectTestModeCookieState } from '../selectors/cookies/selectTestModeCookieState';

/**
 * Возвращает, установлена ли кука интеграционных автотестов
 * Если установлена, то все баннеры не показываются
 */
export const useIsBannerSkippedByQaTestCookie = (): boolean => {
  const isTestMode = useSelector(selectTestModeCookieState);

  return isTestMode;
};
