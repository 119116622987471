import { Button } from '@cian/ui-kit/button';
import { clsx } from 'clsx';
import * as React from 'react';

import * as styles from './CookiesNotification.css';

interface ICookiesNotificationProps {
  noResize?: boolean;
  phone?: boolean;
  onAgreementView(): void;
  onAccept(): void;
}

export const CookiesNotification: React.FC<ICookiesNotificationProps> = ({
  noResize,
  phone,
  onAgreementView,
  onAccept,
}) => {
  const handleAgreementView = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    onAgreementView();
  };

  return (
    <div
      // onPointerDown останавливаем всплытие
      // чтобы модалки не закрывало (например онбординги) при клике "вне" на банер с куками
      // тк банер выше всех модалок по z-index
      onPointerDown={e => e.stopPropagation()}
      className={clsx(styles['container'], noResize && styles['desktop-only'], !noResize && styles['adaptive'])}
      data-testid="cookies-notification"
    >
      <div className={styles['description']}>
        Мы используем {!phone && 'куки-файлы. '}
        <a href="#" className={styles['agreement']} onClick={handleAgreementView}>
          {phone ? 'куки-файлы' : 'Соглашение об использовании'}
        </a>
      </div>
      <div className={styles['button']}>
        <Button onClick={onAccept} theme="fill_white_primary">
          Понятно
        </Button>
      </div>
    </div>
  );
};
