import { ca } from '@cian/analytics';

import { ICianGptPopupParams } from './types';
import { parseGaProducts } from './utils';

export const trackCianGptPopupOpen = ({ source, gaProducts }: ICianGptPopupParams) => {
  const products = gaProducts ? parseGaProducts(gaProducts) : undefined;

  ca('eventSite', {
    name: 'oldevent',
    category: 'ChatBot',
    action: 'Open_sopr',
    page: {
      extra: {
        chat_id: 'chatbot',
        source,
      },
    },
    products,
    useLastProducts: !products,
  });
};
