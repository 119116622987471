import { NoSsr } from '@cian/react-utils';
import { ModalWindow } from '@cian/ui-kit/modal';
import loadable from '@loadable/component';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AuthenticationAlertContainer } from './AuthenticationAlertContainer';
import { trackClose, trackShow } from './tracking';
/**
 * @todo пофиксить импорт https://jira.cian.tech/browse/CD-155930
 */
// eslint-disable-next-line import/no-restricted-paths
import { EStatus, getAuthContextProvider } from '../../../browser/services/authContext';
import { modalClose } from '../../actions/authentication';
import { AuthenticationModal } from '../../components/Authentication';
import { LoadableError } from '../../components/LoadableError';
import { LoadableErrorBoundary } from '../../components/LoadableErrorBoundary';
import { LoadableFallback } from '../../components/LoadableFallback';
import { selectIsRedesignAuthExperimentEnabled } from '../../selectors/experiments/selectIsRedesignAuthExperimentEnabled';
import { IApplicationState, TThunkDispatch } from '../../types/redux';
import { useApplicationContext } from '../../utils/applicationContext';
import { lazyLoad } from '../../utils/authenticationWidget';

import type { IAuthenticationContext } from '@cian/authentication-widget';

const AuthenticationWidgetLoadable = loadable.lib(lazyLoad, { fallback: <LoadableFallback /> });

const AuthenticationModalContainerInner: React.FC = () => {
  const applicationContext = useApplicationContext();
  const { config, logger } = applicationContext;
  const authContextProvider = React.useRef(getAuthContextProvider());
  const disabledSocials = config.get<string[]>('authentication.disabledSocials');

  const [authContext, setAuthContext] = React.useState<IAuthenticationContext | null>();
  const [hasAuthContextInitError, setHasAuthContextInitError] = React.useState(false);

  const isRememberAccountsFlow = config.getStrict<boolean>('auth.rememberAccountsFlow.enabled');
  const isRedesignExperimentEnabled = useSelector(selectIsRedesignAuthExperimentEnabled);
  const isModalOpen = useSelector<IApplicationState, boolean>(state => state.authentication.isModalOpen);
  const useDefaultView = useSelector<IApplicationState, boolean>(state => state.authentication.useDefaultView);
  const authenticationViewData = useSelector<IApplicationState, unknown>(state => state.authentication.viewData);
  const source = useSelector<IApplicationState, string | null>(state => state.authentication.source);

  const dispatch = useDispatch<TThunkDispatch>();

  const handleModalClose = React.useCallback(() => {
    dispatch(modalClose());
    if (authContext) {
      trackClose(authContext.state.view.active);
    }
  }, [authContext, dispatch]);

  const handleLoadAuthContext = React.useCallback(async () => {
    if (authContextProvider.current.status === EStatus.Loading) {
      return;
    }

    setHasAuthContextInitError(false);
    try {
      await authContextProvider.current.init();
      setAuthContext(authContextProvider.current.context);
    } catch (e) {
      setHasAuthContextInitError(true);
      setAuthContext(null);
    }
  }, []);

  const handleRetryAuthWidgetLoad = React.useCallback(() => {
    AuthenticationWidgetLoadable.load();
  }, []);

  React.useEffect(() => {
    if (isModalOpen) {
      trackShow(source);
    }
  }, [isModalOpen]);

  React.useEffect(() => {
    if (isModalOpen) {
      if (!authContextProvider.current.context) {
        handleLoadAuthContext();
      } else {
        setAuthContext(authContextProvider.current.context);
      }
    }
  }, [handleLoadAuthContext, isModalOpen]);

  return (
    <ModalWindow open={isModalOpen} onClose={handleModalClose} outside={false}>
      <AuthenticationModal indent={isRememberAccountsFlow ? 'M' : 'L'}>
        {!authContext && !hasAuthContextInitError && <LoadableFallback />}
        {!authContext && hasAuthContextInitError && <LoadableError onRetry={handleLoadAuthContext} />}
        {authContext && (
          <>
            <AuthenticationAlertContainer onClose={handleModalClose} />
            <LoadableErrorBoundary logger={logger} onRetry={handleRetryAuthWidgetLoad}>
              <AuthenticationWidgetLoadable>
                {({ Provider, Authentication, EActiveView }) => {
                  if (!useDefaultView) {
                    const initialView = isRememberAccountsFlow
                      ? EActiveView.RememberedAccountsSelect
                      : EActiveView.PhoneAuth;
                    authContext.setView(initialView, authenticationViewData);
                  }

                  authContext.setSource(source);

                  return (
                    <Provider context={authContext}>
                      <Authentication
                        disabledSocials={disabledSocials || []}
                        showLogo
                        logger={logger}
                        isRedesignExperimentEnabled={isRedesignExperimentEnabled}
                      />
                    </Provider>
                  );
                }}
              </AuthenticationWidgetLoadable>
            </LoadableErrorBoundary>
          </>
        )}
      </AuthenticationModal>
    </ModalWindow>
  );
};

export function AuthenticationModalContainer() {
  return (
    <NoSsr>
      <AuthenticationModalContainerInner />
    </NoSsr>
  );
}
