import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectGeoSubdomains } from '../selectors/geo/selectGeoSubdomains';
import { useApplicationContext } from '../utils/applicationContext';

export const ALLOWED_PINNED_BUTTON_PAGES: Record<string, RegExp | string> = {
  MAIN_PAGE: '/',
  MAIN_LANDING_PAGE: /^\/(snyat|kupit|posutochno)(\/|$)/,
  FAVORITES_PAGE: /^\/(favorites)\/?/,
} as const;

export const DENIED_MOBILE_APP_BANNER_SUBDOMAINS: Record<string, RegExp | string> = {
  ACCOUNT_PAGE_SUBDOMAIN: 'my',
  RESIDENTIAL_COMPLEX: /^zhk-.+$/,
};

export function useAllowedPinnedButtonPage(): boolean {
  const {
    custom: { originalSubdomain },
  } = useApplicationContext();

  const geoSubdomains = useSelector(selectGeoSubdomains);

  const isAllowedPath = useMemo(() => {
    if (!process.env.IS_BROWSER_BUILD) {
      return false;
    }

    return Object.entries(ALLOWED_PINNED_BUTTON_PAGES).some(([_, page]) => {
      if (typeof page === 'string') {
        return location.pathname === page;
      } else if (page instanceof RegExp) {
        return page.test(location.pathname);
      }

      return false;
    });
  }, []);

  const isAllowedSubdomain = useMemo(() => {
    return geoSubdomains.length > 0
      ? geoSubdomains.includes(originalSubdomain)
      : !Object.entries(DENIED_MOBILE_APP_BANNER_SUBDOMAINS).some(([_, rule]) => {
          if (rule instanceof RegExp) {
            return rule.test(originalSubdomain);
          } else {
            return rule === originalSubdomain;
          }
        });
  }, [geoSubdomains, originalSubdomain]);

  return useMemo(() => isAllowedPath && isAllowedSubdomain, [isAllowedPath, isAllowedSubdomain]);
}
