import * as React from 'react';

import { useTooltip } from './useTooltip';
import { ResidentialComplexesTooltip } from '../../components/ResidentialComplexesTooltip';

interface IResidentialComplexesTooltipContainerProps {
  title: string;
}

export const ResidentialComplexesTooltipContainer: React.FC<IResidentialComplexesTooltipContainerProps> = ({
  title,
}) => {
  const { isOpened, handleClose, handleClick, url, handleMouseLeave, handleMouseOver } = useTooltip();

  return (
    <ResidentialComplexesTooltip
      url={url}
      onClose={handleClose}
      onClick={handleClick}
      onMouseLeave={handleMouseLeave}
      onMouseOver={handleMouseOver}
      title={title}
      isOpened={isOpened}
    />
  );
};
