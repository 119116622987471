import { useMemo } from 'react';

import { useApplicationContext } from '../utils/applicationContext';

const DEFAULT_BLOCKED_UTM_SOURCES = ['telegram'];

/**
 * Возвращает, пришла ли utm метка, при которой мы приостанавливаем показ баннеров
 */
export const useIsBannerSkippedByUTM = (): boolean => {
  const ctx = useApplicationContext();
  const blockedUtmSources = ctx.config.get<string[]>('BX.Banners.BlockedUTMSources') ?? DEFAULT_BLOCKED_UTM_SOURCES;

  return useMemo(() => {
    if (typeof window === 'undefined') {
      return false;
    }

    const params = new URLSearchParams(window.location.search);

    const utmSource = params.get('utm_source');

    if (utmSource) {
      return blockedUtmSources.includes(utmSource);
    }

    return false;
  }, [blockedUtmSources]);
};
