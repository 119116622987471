import { Button } from '@cian/ui-kit/button';
import { Modal, ModalTitle } from '@cian/ui-kit/modal';
import * as React from 'react';

import * as styles from './CookiesAgreementModal.css';
import { LoadableErrorBoundary } from '../LoadableErrorBoundary';
import { CookiesAgreementModalContentLoadable } from './components/CookiesAgreementModalContent';

interface ICookiesAgreementModalProps {
  open: boolean;
  phone?: boolean;
  onAccept(): void;
  onOpenChange(open: boolean): void;
}

export const CookiesAgreementModal: React.FC<ICookiesAgreementModalProps> = ({
  open,
  phone,
  onAccept,
  onOpenChange,
}) => {
  const handleRetryClick = React.useCallback(() => {
    CookiesAgreementModalContentLoadable.load();
  }, []);

  return (
    <Modal
      open={open}
      size="L"
      onOpenChange={onOpenChange}
      header={<ModalTitle>Соглашение об использовании cookie-файлов</ModalTitle>}
      body={
        <div className={styles['content']}>
          <LoadableErrorBoundary onRetry={handleRetryClick}>
            <CookiesAgreementModalContentLoadable />
          </LoadableErrorBoundary>
        </div>
      }
      footer={
        <div className={styles['actions']}>
          <Button
            onClick={() => onOpenChange(false)}
            theme={phone ? 'fill_secondary' : 'fill_white_primary'}
            fullWidth={phone}
          >
            Отменить
          </Button>
          <Button onClick={onAccept} theme="fill_primary" fullWidth={phone}>
            Принять
          </Button>
        </div>
      }
    />
  );
};
