import { createConsumer, createSubscriber, IEvent, TSubscriberHandler } from '@cian/events-log';
import Cookies from 'js-cookie';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackClickTooltip, trackShowTooltip } from './tracking';
import { RESIDENTIAL_COMPLEXES_TOOLTIP_SHOWN } from '../../constants/cookies';
import { RESIDENTIAL_COMPLEXES_TOOLTIP_TOPIC } from '../../constants/residentialComplexesTooltipTopic';
import { selectResidentialComplexesTooltipCookieState } from '../../selectors/cookies/selectResidentialComplexesTooltipCookieState';
import { selectDropdownOpen } from '../../selectors/dropdown';
import { selectIsResidentialComplexesTooltipExperimentEnabled } from '../../selectors/experiments/selectIsResidentialComplexesTooltipExperimentEnabled';
import { useApplicationContext } from '../../utils/applicationContext';

export function useTooltip() {
  const { config } = useApplicationContext();

  const hideTimeoutMs = config.get<number>('residentialComplexesTooltip.hideTimeout') || 5000;

  const hideTimeoutRef = React.useRef<number | null>(null);

  const [isOpened, setOpened] = React.useState(false);
  const [url, setUrl] = React.useState('#');

  const open = useSelector(selectDropdownOpen);
  const residentialComplexesTooltipCookie = useSelector(selectResidentialComplexesTooltipCookieState);
  const isResidentialComplexesTooltipExperimentEnabled = useSelector(
    selectIsResidentialComplexesTooltipExperimentEnabled,
  );

  React.useEffect(() => {
    if (open && isOpened) {
      setOpened(false);
    }
  }, [open, isOpened]);

  const handleClose = React.useCallback(() => {
    if (hideTimeoutRef.current) {
      window.clearTimeout(hideTimeoutRef.current);
      hideTimeoutRef.current = null;
    }

    setOpened(false);
  }, []);

  const handleClick = React.useCallback(() => {
    if (hideTimeoutRef.current) {
      window.clearTimeout(hideTimeoutRef.current);
      hideTimeoutRef.current = null;
    }

    setOpened(false);
    trackClickTooltip();
  }, []);

  const handleMouseOver = React.useCallback(() => {
    if (hideTimeoutRef.current) {
      window.clearTimeout(hideTimeoutRef.current);
      hideTimeoutRef.current = null;
    }
  }, []);

  const handleMouseLeave = React.useCallback(() => {
    hideTimeoutRef.current = window.setTimeout(() => {
      hideTimeoutRef.current = null;
      setOpened(false);
    }, hideTimeoutMs);
  }, [hideTimeoutMs]);

  React.useEffect(() => {
    const tooltipConsumer = createConsumer({ topic: RESIDENTIAL_COMPLEXES_TOOLTIP_TOPIC });

    const tooltipSubscriber = createSubscriber(((event: IEvent<string>) => {
      if (
        event.type === 'open' &&
        isResidentialComplexesTooltipExperimentEnabled &&
        !residentialComplexesTooltipCookie
      ) {
        setUrl(`${event.value.replace('object_type%5B0%5D=1', 'object_type%5B0%5D=2')}&with_newobject=1`);
        setOpened(true);

        Cookies.set(RESIDENTIAL_COMPLEXES_TOOLTIP_SHOWN, '1', {
          domain: '.cian.ru',
          expires: 7,
        });

        trackShowTooltip();
      }

      hideTimeoutRef.current = window.setTimeout(() => {
        hideTimeoutRef.current = null;
        setOpened(false);
      }, hideTimeoutMs);
    }) as TSubscriberHandler);

    tooltipConsumer.subscribe(tooltipSubscriber);

    return () => {
      tooltipConsumer.unsubscribe(tooltipSubscriber);
      tooltipConsumer.destroy();
    };
  }, [hideTimeoutMs, isResidentialComplexesTooltipExperimentEnabled, residentialComplexesTooltipCookie]);

  return React.useMemo(() => {
    return {
      isOpened,
      handleClose,
      handleClick,
      handleMouseLeave,
      handleMouseOver,
      url,
    };
  }, [isOpened, handleClose, handleClick, handleMouseLeave, handleMouseOver, url]);
}
