import { ca } from '@cian/analytics';

import { IApplicationState } from '../../types/redux';

interface ITrackingUserAuthorized {
  realtyId: number | null;
}

export type TTrackingUser = ITrackingUserAuthorized | {};

export const getTrackingUser = (state: IApplicationState): TTrackingUser => {
  const { user } = state;

  if (user.isAuthenticated) {
    return {
      userId: user.realtyId,
    };
  }

  return {};
};

export function trackCookieAgreementlick(user: TTrackingUser) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'cookies_informer',
    action: 'click_sopr',
    user,
    page: '',
  });
}
