import { LinkButton, OnboardingTooltip, Theme, UIHeading4 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './ResidentialComplexesTooltip.css';
import buildingSrc from './assets/building.png';

interface IResidentialComplexesTooltipProps {
  isOpened: boolean;
  onClick(): void;
  onClose(): void;
  onMouseOver(): void;
  onMouseLeave(): void;
  title: string;
  url: string;
}

export const ResidentialComplexesTooltip: React.FC<IResidentialComplexesTooltipProps> = ({
  isOpened,
  onClick,
  onClose,
  onMouseOver,
  onMouseLeave,
  title,
  url,
}) => {
  return (
    <OnboardingTooltip
      onClose={onClose}
      flip={false}
      placement="bottom-start"
      theme="black"
      open={isOpened}
      content={
        <Theme theme="dark">
          <div className={styles['container']} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
            <img width={40} height={40} src={buildingSrc} alt="Здание" />
            <div className={styles['content']}>
              <UIHeading4>
                Подберите новое жильё
                <br />
                на выгодных условиях
              </UIHeading4>
              <span>
                <LinkButton href={url} target="_blank" size="XS" theme="fill_primary" onClick={onClick}>
                  Хочу посмотреть
                </LinkButton>
              </span>
            </div>
          </div>
        </Theme>
      }
    >
      <span>{title}</span>
    </OnboardingTooltip>
  );
};
