export const COOKIES_AGREEMENT_ACCEPTED_KEY = 'cookie_agreement_accepted';

export const MOBILE_APP_FULLSCREEN_BANNER_COOKIE_NAME = 'number_banner_appearances';

export const QA_TEST_MODE = 'QA-TestMode';

export const ONBOARDING_SEEN_COOKIE_NAME = 'nbrdng_sn';

export const LOGIN_BUTTON_TOOLTIP_KEY = 'login_button_tooltip_key';

export const RESIDENTIAL_COMPLEXES_TOOLTIP_SHOWN = 'residential_complexes_tooltip_shown';
