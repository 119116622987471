import * as React from 'react';

import { useCianGptPopupEvents } from './hooks';
import { trackCianGptPopupOpen } from './tracking';
import { ICianGptPopupParams } from './types';
import { buildChatSrc } from './utils';
import { CianGptPopup } from '../../components/CianGptPopup';

export const CianGptPopupContainer = () => {
  const [popupData, setPopupData] = React.useState<ICianGptPopupParams | null>(null);

  const clearPopupData = React.useCallback(() => {
    setPopupData(null);
  }, []);

  useCianGptPopupEvents({ isOpened: !!popupData, onPopupOpen: setPopupData });

  const chatSrc = React.useMemo(() => (popupData ? buildChatSrc(popupData) : ''), [popupData]);

  React.useEffect(() => {
    if (popupData) {
      trackCianGptPopupOpen(popupData);
    }
  }, [popupData]);

  if (!chatSrc) {
    return null;
  }

  return <CianGptPopup onClose={clearPopupData} chatSrc={chatSrc} />;
};
