import { ca } from '@cian/analytics';

/* istanbul ignore next */
export function trackInfoBlockShow() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'main_page',
    action: 'top_menu_show_sopr',
    label: 'banner',
    page: {
      extra: {
        rubric: 'other',
        name: 'MOEX',
      },
    },
  });
}

/* istanbul ignore next */
export function trackInfoBlockClick() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'main_page',
    action: 'top_menu_click',
    label: 'banner',
    page: {
      extra: {
        rubric: 'other',
        name: 'MOEX',
      },
    },
  });
}
