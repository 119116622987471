import { ca } from '@cian/analytics';

export function trackShowTooltip() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'top_menu',
    action: 'show',
    label: 'peretok_tooltip_newb',
  });
}

export function trackClickTooltip() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'top_menu',
    action: 'click',
    label: 'peretok_tooltip_newb',
  });
}
