export const ICONS = {
  deals: require('./assets/deals.svg'),
  complaints: require('./assets/complaints.svg'),
  hiddenOffers: require('./assets/hiddenOffers.svg'),
  requestAgentHelp: require('./assets/requestAgentHelp.svg'),
  businessAnalytics: require('./assets/businessAnalytics.svg'),
  messages: require('./assets/messages.svg'),
  favorites: require('./assets/favorites.svg'),
  savedSearches: require('./assets/savedSearches.svg'),
  flatValuation: require('./assets/flatValuation.svg'),
  helpCenter: require('./assets/helpCenter.svg'),
  marketPulse: require('./assets/marketPulse.svg'),
  mortgageCalculator: require('./assets/mortgageCalculator.svg'),
  mortgageForm: require('./assets/mortgageForm.svg'),
  placeOffer: require('./assets/placeOffer.svg'),
  reportProblem: require('./assets/reportProblem.svg'),
  notifications: require('./assets/notifications.svg'),
  search: require('./assets/search.svg'),
  saleRent: require('./assets/saleRent.svg'),
  e2eMortgage: require('./assets/e2eMortgage.svg'),
  inspection: require('./assets/inspection.svg'),
  myDeals: require('./assets/myDeals.svg'),
};
