import { ECookiesActionTypes } from '../../actions/cookies';
import { ICookiesState } from '../../types/cookies';
import { TReduxActions } from '../../types/redux';

const defaultFetures: ICookiesState = {
  mobileBanner: false,
  profiling: false,
  testMode: false,
  accepted: false,
  onboarding: false,
  loginButtonTooltip: false,
  residentialComplexesTooltip: false,
};

export function cookiesReducer(state: ICookiesState = defaultFetures, action: TReduxActions): ICookiesState {
  switch (action.type) {
    case ECookiesActionTypes.CookiesAccepted:
      return {
        ...state,
        accepted: true,
      };
  }

  return state;
}
