import { clsx } from 'clsx';
import * as React from 'react';

import * as styles from './Logo.css';

interface ILogoProps {
  url: string;
  noResize: boolean;
  src: string | null;
  onClick: () => void;
}

export const Logo: React.FC<ILogoProps> = ({ noResize, url, src, onClick }) => {
  const isWebM = src?.endsWith('.webm');

  const style = React.useMemo(() => {
    return src && !isWebM ? { backgroundImage: `url('${src}')` } : undefined;
  }, [src, isWebM]);

  if (isWebM) {
    return (
      <video
        data-name="Logo"
        className={clsx(styles['container'], noResize && styles['static'], styles['video'])}
        autoPlay
        muted
        loop
        playsInline
        onClick={() => (window.location.href = url)}
      >
        <source src={src || undefined} type="video/webm" />
        <a
          data-name="Logo"
          href={url}
          className={clsx(styles['container'], noResize && styles['static'])}
          style={style}
          onClick={onClick}
        >
          Your browser does not support the video tag.
        </a>
      </video>
    );
  }

  return (
    <a
      data-name="Logo"
      href={url}
      className={clsx(styles['container'], noResize && styles['static'])}
      style={style}
      onClick={onClick}
    />
  );
};
