import { ICianGptPopupParams } from '../types';

export const buildChatSrc = ({ source, gaProducts, pageType }: ICianGptPopupParams) => {
  const query = [
    ['hostType', 'frame'],
    ['chatbot', '1'],
    gaProducts && ['gaProducts', encodeURIComponent(gaProducts)],
    source && ['source', source],
    pageType && ['pageType', pageType],
  ]
    .filter(Boolean)
    .map(([key, value]: string[]) => `${key}=${value}`)
    .join('&');

  return `https://www.cian.ru/dialogs/?${query}`;
};
