import { useDeviceType } from '@cian/ui-kit';
import { useMemo } from 'react';

export function usePinnedButtonEntryPoint(): boolean {
  const deviceType = useDeviceType();

  return useMemo(
    () => deviceType === 'phone',
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
