import { UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './InfoBlock.css';

interface IInfoBlockProps {
  onShow: () => void;
  onClick: () => void;
}

/* istanbul ignore next */
export const InfoBlock: React.FC<IInfoBlockProps> = ({ onShow, onClick }) => {
  React.useEffect(onShow, [onShow]);

  return (
    <div className={styles['container']}>
      <div className={styles['block']}>
        <UIText2>Циан возвращается на Мосбиржу с новым тикером CNRU.</UIText2>
        <a
          data-name="InfoBlock"
          href="https://ir.ciangroup.ru/ru/"
          target="_blank"
          rel="noreferrer"
          className={styles['href']}
          onClick={onClick}
        >
          <UIText2 color="primary_100">Подробнее</UIText2>
        </a>
      </div>
    </div>
  );
};
