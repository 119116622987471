import * as React from 'react';

import { trackInfoBlockClick, trackInfoBlockShow } from './tracking';
import { InfoBlock } from '../../components/InfoBlock/InfoBlock';
import { useApplicationContext } from '../../utils/applicationContext';

/* istanbul ignore next */
export function InfoBlockContainer() {
  const { config } = useApplicationContext();

  const moexLogo = config.get<string>('audience.cianCNRULogoUrl');

  const handleShow = React.useCallback(() => {
    trackInfoBlockShow();
  }, []);

  const handleClick = React.useCallback(() => {
    trackInfoBlockClick();
  }, []);

  if (!moexLogo) {
    return null;
  }

  return <InfoBlock onShow={handleShow} onClick={handleClick} />;
}
