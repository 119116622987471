import * as React from 'react';
import { useSelector } from 'react-redux';

import { DropdownBackdrop } from '../../components/Dropdown';
import { selectDropdownOpen } from '../../selectors/dropdown';

export const DropdownBackdropContainer: React.FC = () => {
  const open = useSelector(selectDropdownOpen);

  if (!open) {
    return null;
  }

  return <DropdownBackdrop />;
};
