import { UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import styles from './CianGptButton.css';
import cubeIcon from './assets/icon.png';

interface ICianGptButtonProps {
  onClick: VoidFunction;
}

export const CianGptButton: React.FC<ICianGptButtonProps> = ({ onClick }) => {
  return (
    <button className={styles['gpt-button']} onClick={onClick}>
      <img src={cubeIcon} alt="Cube" width={16} height={16} />

      <UIText2 color="primary_100">Умный Циан-помощник</UIText2>
    </button>
  );
};
