import { getGlobalEventsLogs } from '@cian/events-log';
import { useCallback } from 'react';
import * as React from 'react';

import { CianGptButton } from '../../components/CianGptButton';
import { CIAN_GPT_POPUP_TOPIC } from '../../constants/cianGptPopup';

const EVENT_VALUE = {
  source: 'Head',
};

export const CianGptButtonContainer = () => {
  const handleClick = useCallback(() => {
    const globalLog = getGlobalEventsLogs();

    globalLog.produce(CIAN_GPT_POPUP_TOPIC, { type: 'open', value: EVENT_VALUE });
  }, []);

  return <CianGptButton onClick={handleClick} />;
};
