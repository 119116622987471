import { IconButton, Spinner, useDeviceType } from '@cian/ui-kit';
import { IconActionClose16 } from '@cian/ui-kit-design-tokens/icons';
import { clsx } from 'clsx';
import * as React from 'react';

import styles from './CianGptPopup.css';

interface ICianGtpPopupProps {
  chatSrc: string;

  onClose: VoidFunction;
}

export const CianGptPopup: React.FC<ICianGtpPopupProps> = ({ onClose, chatSrc }) => {
  const device = useDeviceType();
  const [isLoading, setIsLoading] = React.useState(true);

  const onLoad = () => setIsLoading(false);

  return (
    <div
      className={clsx(styles['container'], {
        [styles['container--fullscreen']]: device !== 'desktop',
      })}
      data-testid="CianGptPopup"
    >
      <span className={styles['close']} data-testid="CianGptPopupCloseButton">
        <IconButton icon={<IconActionClose16 />} onClick={onClose} />
      </span>
      {isLoading && (
        <span className={styles['spinner']} data-testid="spinner">
          <Spinner size={16} />
        </span>
      )}
      <iframe data-testid="ChatModal" className={styles['iframe']} src={chatSrc} onLoad={onLoad} onError={onLoad} />
    </div>
  );
};
