/* eslint-disable */
//tslint:disable
import { IMicrofrontendManifest } from '@cian/mf-registry/shared';
import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import {
  TGetChatsWidgetMicrofrontendModel,
  IGetChatsWidgetMicrofrontendRequest,
  IMappers,
  TGetChatsWidgetMicrofrontendResponse,
} from './types';

const defaultConfig = {
  apiType: 'public',
  assertStatusCodes: [200],
  method: 'GET',
  microserviceName: 'frontend-chats',
  pathApi: '/v1/get-chats-widget/',
  requestType: 'json',
  responseType: 'json',
} as TGetChatsWidgetMicrofrontendModel;

function createGetChatsWidgetMicrofrontendModel(
  parameters: IGetChatsWidgetMicrofrontendRequest,
): TGetChatsWidgetMicrofrontendModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetChatsWidgetMicrofrontendOptions<TResponse200> {
  httpApi: IHttpApi;
  parameters: IGetChatsWidgetMicrofrontendRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200>;
}

async function fetchGetChatsWidgetMicrofrontend<TResponse200>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetChatsWidgetMicrofrontendOptions<TResponse200>): Promise<TResponse200 | TGetChatsWidgetMicrofrontendResponse> {
  const { response, statusCode } = await httpApi.fetch(createGetChatsWidgetMicrofrontendModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IMicrofrontendManifest);
    }
  }

  return { response, statusCode } as TGetChatsWidgetMicrofrontendResponse;
}
export { defaultConfig, createGetChatsWidgetMicrofrontendModel, fetchGetChatsWidgetMicrofrontend };
