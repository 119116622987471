import { ca } from '@cian/analytics';

import { TPageType } from '../../types/page';
import { pageTypeToAnalyticsCategory } from '../../utils/pageType';

export function trackPlaceOfferClick(pageType: TPageType) {
  ca('eventSite', {
    name: 'oldevent',
    category: pageTypeToAnalyticsCategory(pageType),
    action: 'top_menu',
    label: 'add_adv',
  });
}

export function trackAuthenticateClick(pageType: TPageType) {
  ca('eventSite', {
    name: 'oldevent',
    category: pageTypeToAnalyticsCategory(pageType),
    action: 'top_menu',
    label: 'login',
  });
}
