import { createConsumer, createSubscriber, IEvent } from '@cian/events-log';
import { useEffect, useRef } from 'react';

import { CIAN_GPT_POPUP_TOPIC } from '../../../constants/cianGptPopup';
import { ICianGptPopupParams } from '../types';

interface IParams {
  isOpened: boolean;

  onPopupOpen(params: ICianGptPopupParams): void;
}

export const useCianGptPopupEvents = ({ isOpened, onPopupOpen }: IParams) => {
  const opened = useRef(isOpened);

  useEffect(() => {
    const cianGptPopupConsumer = createConsumer({ topic: CIAN_GPT_POPUP_TOPIC });

    const cianGptPopupSubscriber = createSubscriber((event: IEvent<unknown>) => {
      const openedValue = opened.current;

      if (event.type === 'open' && !openedValue) {
        onPopupOpen(event.value);
      }
    });

    cianGptPopupConsumer.subscribe(cianGptPopupSubscriber);

    return () => {
      cianGptPopupConsumer.unsubscribe(cianGptPopupSubscriber);
      cianGptPopupConsumer.destroy();
    };
  }, [onPopupOpen]);

  useEffect(() => {
    opened.current = isOpened;
  }, [isOpened]);
};
